<template>
  <b-container class="p-3 mt-2 justify-content-center align-items-center message">
    <b-row class="justify-content-start mb-1">
      <b-col>
        <h4>Código do PN: {{ id }}</h4>
      </b-col>
    </b-row>

    <b-row>

      <CardRobotLogs v-if="note" :robotNote = "JSON.parse(note)"/>
    </b-row>

    <b-row class="justify-content-start mb-1">
      <b-col>
        <b-dropdown id="dropdown-dropup" :text="dropdownText" class="dropgroup-poli">
          <b-dropdown-item @click="selectAllNotes"
            >Todas as notas</b-dropdown-item
          >
          <b-dropdown-item @click="selectReconciliedNotes"
            >Notas Reconciliadas</b-dropdown-item
          >
          <b-dropdown-item @click="selectUnreconciliedNotes"
            >Notas não reconciliadas</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CardRobotLogs from '@/components/CardRobotLogs'
export default {
  props: {
    id: {
      required: true,
    },
    note: {
      required: true,
    },
  },
  components:{
    CardRobotLogs
  },
  computed: {

    /**
     * Return a string based on the variable filter reconciliation
     * @returns {string}
     */
    dropdownText() {
      if (this.$root.filters.reconciliation == null) return "Todas as notas";
      if (this.$root.filters.reconciliation == true)
        return "Notas Reconciliadas";
      if (this.$root.filters.reconciliation == false)
        return "Notas Não Reconciliadas";
      return '';
    },
  },
  methods: {

    /**
     * turn the variable filter reconciliation equals null
     */
    selectAllNotes() {
      this.$root.filters.reconciliation = null;
    },
    /**
     * turn the variable filter reconciliation equals true
     */
    selectReconciliedNotes() {
      this.$root.filters.reconciliation = true;
    },
    /**
     * turn the variable filter reconciliation equals false
     */
    selectUnreconciliedNotes() {
      this.$root.filters.reconciliation = false;
    },
  },
};
</script>

<style scoped>

.message{
  min-width: 310px
}

</style>