<template>
  <b-row>
    <b-col cols="12">
      <b-row class="justify-content-between align-items-center">
        <b-col class="text-center">
          <FilterBusinessPartner />
        </b-col>
        <b-col class="text-center">
          <FilterBranch />
        </b-col>
        <b-col class="text-center">
          <FilterCalendar />
        </b-col>
        <b-col class="text-center">
          <ViewerBalance :value="totalBalance" />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <FilterBadges />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="d-flex justify-content-end align-items-center">
          <ButtonSync @dataUpdated="this.loadTable" />
        </b-col>
      </b-row>

      <b-row class="justify-content-center align-items-center">
        <b-col cols="12" align-self="center">
          <TableBusinessPartners :items="request" :busy="loading" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import FilterBusinessPartner from "@/components/FilterBusinessPartner";
import FilterBadges from "@/components/FilterBadges";
import FilterBranch from "@/components/FilterBranch";
import ViewerBalance from "@/components/ViewerBalance";
import FilterCalendar from "@/components/FilterCalendar";
import TableBusinessPartners from "@/components/TableBusinessPartners";
import ButtonSync from "@/components/ButtonSync";

export default {
  components: {
    FilterBusinessPartner,
    FilterBadges,
    FilterBranch,
    ViewerBalance,
    FilterCalendar,
    TableBusinessPartners,
    ButtonSync,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.loadTable();
    // console.log(this.$root.businessPartnerItems.length)
  },
  computed: {
    /**
     * Just return the variable in root businessPartnerItems
     * @returns {object}
     */
    

    branchesFilter() {
      return this.$root.filters.branches;
    },

    initialDateFilter() {
      return this.$root.filters.initialDate;
    },

    endDateFilter() {
      return this.$root.filters.endDate;
    },

    request() {
      let aux = JSON.parse(JSON.stringify(this.$root.businessPartnerItems));

      try {
        if (this.branchesFilter.length != 0) {
          this.branchesFilter.forEach((el) => {
            aux.forEach((element) => {
              let obj = JSON.parse(JSON.parse(element["reconciliation_note"]));
              if (obj) {
                if (el in obj) {
                  element[el] = obj[el];
                }
                else{
                  element[el] = ""
                }
              }
              else{
                element[el] = ""
              }
            });
          });
        }
      } catch (err) {
        console.log(err);
      }

      return aux;
    },

    /**
     * Just return the variable in root filter partners
     * @returns {string[]}
     */
    partnerFilter() {
      return this.$root.filters.partners;
    },

    /**
     * Sum all partner_bills and adiantment_bills value of all elements in array filteredBusinessPartner
     * @returns {number}
     */
    totalBalance() {
      var value = 0;
      if (this.request.length) {
        this.request.forEach((element) => {
          value += Number(element.partnerBills) + Number(element.adiantmentBills);
        });
      }

      return value;
    },
  },
  watch: {
    /**
     * Verify if the filter partner was changes, and if this happens, call the function appendPartner
     * for each element in filter partner variable
     */
    partnerFilter: function () {
      this.$root.businessPartnerItems = [];
      this.$root.workedTablePages = [];
      this.fetchBusinessPartners(0, 1000);
    },
    branchesFilter: function () {
      this.$root.businessPartnerItems = [];
      this.$root.workedTablePages = [];
      this.fetchBusinessPartners(0, 1000);
    },
    initialDateFilter: function () {
      this.$root.workedTablePages = [];
      this.$root.businessPartnerItems = [];
      this.fetchBusinessPartners(0, 1000);
    },
    endDateFilter: function () {
      this.$root.workedTablePages = [];
      this.$root.businessPartnerItems = [];
      this.fetchBusinessPartners(0, 1000);
    },
  },
  methods: {
    async loadTable() {
      if(this.$root.workedTablePages.length<1){
        this.$root.businessPartnerItems = [];
        this.$root.workedTablePages = [];
        this.fetchBusinessPartners(0, 1000);
      } 
    },
    /**
     * Makes a request to get the first 200 partners, and verify if they are not insterte in
     * variable businessPartnerItems
     */
    async fetchBusinessPartners(offset, limit) {
      this.loading = true;
      this.$root.requesting = true;
      try {
        const response = await this.$root.instance.get(
          "/BusinessPartner/filter",
          {
            headers: {
              Offset: offset,
              Limit: limit,
              Authorization: "Bearer " + sessionStorage.token,
            },
            params: {
              businessPartners: this.$root.filters.partners,
              initialDate: this.$root.filters.initialDate,
              endDate: this.$root.filters.endDate,
              branch: this.$root.filters.branches,
            },
          }
        );
        if (response.status == 200) {
          response.data.forEach((el) => {
            this.$root.businessPartnerItems.push(el);
          });
          this.$root.workedTablePages.push(1);
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }

      this.loading = false;
      this.$root.requesting = false;
    },
  },
};
</script>
