<template>
  <b-row>
    <b-col cols="12">
      <b-row class="justify-content-between align-items-center">
        <b-col class="text-center">
          <FilterBusinessPartner />
        </b-col>
        <b-col class="text-center">
          <FilterBranch />
        </b-col>
        <b-col class="text-center">
          <FilterCalendar />
        </b-col>
        <b-col class="text-center">
          <ViewerBalance :value="totalBalance" />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <FilterBadges />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="d-flex justify-content-start align-items-center">
          <b-button-group>
            <b-button @click="graph = 1" :disabled="this.$root.requesting" class="btn-poli" :pressed="graph === 1"> Retificados </b-button>
            <b-button @click="graph = 2" :disabled="this.$root.requesting" class="btn-poli" :pressed="graph === 2"> Exceções </b-button>
            <b-button @click="graph = 3" :disabled="this.$root.requesting" class="btn-poli" :pressed="graph === 3"> Identificar </b-button>
          </b-button-group>
          <b-button @click="fetchAllBusinessPartners" :disabled="this.$root.requesting" class="btn-poli" style="margin-left: 10px"> Carregar dados </b-button>
        </b-col>
        <b-col class="d-flex justify-content-end align-items-center">
          <ButtonSync @dataUpdated="this.loadData" />
        </b-col>
      </b-row>

      <div class="text-center text-load my-2" v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Carregando...</strong>
      </div>

      <b-row class="justify-content-center align-items-center">
        <b-col cols="12" align-self="center">
          <ChartRetified v-if="graph===1 && !loading" :dataRetified="dataRetified"/>
          <ChartExceptions v-if="graph===2 && !loading" :dataException="dataException"/>
          <ChartIdentify v-if="graph===3 && !loading" :dataIdentified="dataIdentified"/>     
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import FilterBusinessPartner from "@/components/FilterBusinessPartner";
import FilterBadges from "@/components/FilterBadges";
import FilterBranch from "@/components/FilterBranch";
import ViewerBalance from "@/components/ViewerBalance";
import FilterCalendar from "@/components/FilterCalendar";
// import TableBusinessPartners from "@/components/TableBusinessPartners";
import ButtonSync from "@/components/ButtonSync";
import ChartRetified from "@/components/ChartRetified"
import ChartIdentify from "@/components/ChartIdentify"
import ChartExceptions from "@/components/ChartExceptions"
export default {
  components: {
    FilterBusinessPartner,
    FilterBadges,
    FilterBranch,
    ViewerBalance,
    FilterCalendar,
    // TableBusinessPartners,
    ButtonSync,
    ChartRetified,
    ChartIdentify,
    ChartExceptions,
  },
  data() {
    return {
      dataIdentified: {
        identified : 0,
        unidentified: 0,
      },
      dataRetified: {
        unrectified: 0,
        manuallyRetified: 0,
        autoRetified: 0,
      },
      dataException: {
        null: 0,
        totallyReconciled: 0,
        partiallyReconciled: 0,
        exceptionsCount: 0,
        exceptions: [
          {
            name: "Valor de Crédito maior do que de Débito",
            message: "é maior que o de Débito",
            y: 0,
          },
          {
            name: "Campo REF3 divergente entre matriz e filial",
            message: "campo REF3 está divergente entre matriz e filial",
            y: 0,
          },
          {
            name: "Existem mais débitos do que créditos",
            message: "existem mais débitos do que créditos",
            y: 0,
          },
          {
            name: "Cliente inativo",
            message: "is inactive",
            y: 0,
          },
          {
            name: "Nota vinculada a pedido de devolução",
            message: "vinculada a um pedido",
            y: 0,
          }
        ]
      },
      bills: [],
      graph: 0,
      loading: false,
    };
  },
  mounted: function () {
    this.loadData();
  },
  computed: {
    /**
     * Just return the variable in root businessPartnerItems
     * @returns {object}
     */
    
    branchesFilter() {
      return this.$root.filters.branches;
    },

    initialDateFilter() {
      return this.$root.filters.initialDate;
    },

    endDateFilter() {
      return this.$root.filters.endDate;
    },

    request() {
      let aux = JSON.parse(JSON.stringify(this.$root.businessPartnerItems));

      try {
        if (this.branchesFilter.length != 0) {
          this.branchesFilter.forEach((el) => {
            aux.forEach((element) => {
              let obj = JSON.parse(JSON.parse(element["reconciliation_note"]));
              if (obj) {
                if (el in obj) {
                  element[el] = obj[el];
                }
                else{
                  element[el] = ""
                }
              }
              else{
                element[el] = ""
              }
            });
          });
        }
      } catch (err) {
        console.log(err);
      }

      return aux;
    },

    /**
     * Just return the variable in root filter partners
     * @returns {string[]}
     */
    partnerFilter() {
      return this.$root.filters.partners;
    },

    /**
     * Sum all partner_bills and adiantment_bills value of all elements in array filteredBusinessPartner
     * @returns {number}
     */
    totalBalance() {
      var value = 0;
      if (this.request.length) {
        this.request.forEach((element) => {
          value += element.partnerBills + element.adiantmentBills;
        });
      }

      return value;
    },
  },
  watch: {
    /**
     * Verify if the filter partner was changes, and if this happens, call the function appendPartner
     * for each element in filter partner variable
     */
    partnerFilter: function () {
      this.$root.businessPartnerItems = [];
      this.$root.workedTablePages = [];
      this.fetchAllBusinessPartners();
    },
    branchesFilter: function () {
      this.$root.businessPartnerItems = [];
      this.$root.workedTablePages = [];
      this.fetchAllBusinessPartners();
    },
    initialDateFilter: function () {
      this.$root.workedTablePages = [];
      this.$root.businessPartnerItems = [];
      this.fetchAllBusinessPartners();
    },
    endDateFilter: function () {
      this.$root.workedTablePages = [];
      this.$root.businessPartnerItems = [];
      this.fetchAllBusinessPartners();
    },
  },
  methods: {
    async loadData() {
      if(!this.$root.workedTablePages.includes(99)){
        this.$root.businessPartnerItems = [];
        this.$root.workedTablePages = [];
        this.fetchAllBusinessPartners();
      } else{
        this.setGraphData();
      } 
      
      if(this.graph === 0){
        this.graph = 1;
      }    
    },

    async fetchAllBusinessPartners() {
      this.loading = true;
      this.$root.requesting = true;

      this.$root.workedTablePages = [];
      this.$root.businessPartnerItems = [];

      try {
        const response = await this.$root.instance.get(
          "/BusinessPartner/filter",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.token,
            },
            params: {
              businessPartners: this.$root.filters.partners,
              initialDate: this.$root.filters.initialDate,
              endDate: this.$root.filters.endDate,
              branch: this.$root.filters.branches,
            },
          }
        );
        if (response.status == 200) {
          response.data.forEach((el) => {
            this.$root.businessPartnerItems.push(el);
          });
          this.$root.workedTablePages.push(99);
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }

      this.loading = false;
      this.$root.requesting = false;

      this.setGraphData();
    },

    async setGraphData(){
      this.loading = true;
      this.$root.requesting = true;

      this.dataIdentified = {
        identified : 0,
        unidentified: 0,
      };

      this.dataRetified = {
        unrectified: 0,
        manuallyRetified: 0,
        autoRetified: 0,
      };

      this.dataException = {
        null: 0,
        totallyReconciled: 0,
        partiallyReconciled: 0,
        exceptionsCount: 0,
        exceptions: [
          {
            name: "Valor de Crédito maior do que de Débito",
            message: "é maior que o de Débito",
            y: 0,
          },
          {
            name: "Campo REF3 divergente entre matriz e filial",
            message: "campo REF3 está divergente entre matriz e filial",
            y: 0,
          },
          {
            name: "Existem mais débitos do que créditos",
            message: "existem mais débitos do que créditos",
            y: 0,
          },
          {
            name: "Cliente inativo",
            message: "is inactive",
            y: 0,
          },
          {
            name: "Nota vinculada a pedido de devolução",
            message: "vinculada a um pedido",
            y: 0,
          },
        ],
      },

      this.$root.businessPartnerItems.forEach(businessPartner => {
        if(businessPartner.id==='IDENTIFICAR'){
          this.dataIdentified.unidentified = Math.round(businessPartner.adiantmentBills);
        }

        if(businessPartner.rectified){
          if(businessPartner.adiantmentBills === 0 && businessPartner.partnerBills === 0){
            this.dataRetified.autoRetified++;
          } else{
            this.dataRetified.manuallyRetified++;
          }
        } else{
          this.dataRetified.unrectified++;
        }

        // if(businessPartner.reconciliation_note){
        //   this.dataException.exceptions.forEach(exception => {
        //     if(businessPartner.reconciliation_note.includes(exception.message)){
        //       if(businessPartner.reconciliation_note.includes('Reconciliado')){
        //         this.dataException.partiallyReconciled++;
        //       } else{
        //         exception.y++;
        //         this.dataException.exceptionsCount++;
        //       }
        //     }          
        //   })

        //   if(businessPartner.reconciliation_note.includes('Reconciliado')){
        //     if(!businessPartner.reconciliation_note.includes('ERRO')){
        //       this.dataException.totallyReconciled++;
        //     }  
        //   }

        // } else{
        //   this.dataException.null++;
        // }

        if(businessPartner.reconciliation_note){
          if(businessPartner.reconciliation_note.includes('Reconciliado')){
            if(businessPartner.reconciliation_note.includes('ERRO') && !this.dataException.exceptions.some(ex=>businessPartner.reconciliation_note.includes(ex.message) )){
              this.dataException.partiallyReconciled++;
            } else{
              this.dataException.totallyReconciled++;
            }
          } else{
            if(businessPartner.reconciliation_note.includes('ERRO')){
              this.dataException.exceptions.forEach(exception => {
                if(businessPartner.reconciliation_note.includes(exception.message)){
                  exception.y++;
                  this.dataException.exceptionsCount++;
                }
              })
            } 
          }

        } else{
          this.dataException.null++;
        }


      });

      this.dataIdentified.identified = this.totalBalance>0? Math.round(this.totalBalance) : -1*Math.round(this.totalBalance);

      this.loading = false;
      this.$root.requesting = false; 

    }
  },
};
</script>
