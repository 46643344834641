<template>
  <div class="menu-card p-2 border-right">
    <div>
      <div class="poliedro-logo">
        <img
          src="../assets/images/logo_poliedro.jpg"
          :style="{ width: '90%' }"
          class="center"
        />
      </div>
      <b-nav pills :vertical="true" align="left">
        <div v-for="(item, index) in menuitems" :key="index">
          <b-nav-item
            v-if="item.name != 'PartnerBills'"
            class="my-2 nav-poli"
            @click="navigate(item.uri)"
            :active="$route.name === item.name"
          >
            <b-icon :icon="item.icon" />
            <b> &nbsp;&nbsp;&nbsp;{{ item.label }} </b>
          </b-nav-item>

          <div v-else :class="{'pop': show}">
            <b-nav-item
              class="my-2 nav-poli"
              v-b-toggle.collapse-menu
              :active="$route.name === item.name"
            >
              <b-icon :icon="item.icon" />
              <b> &nbsp;&nbsp;&nbsp;{{ item.label }} </b>
            </b-nav-item>
            <b-collapse id="collapse-menu" v-model="show">
              <b-container >
                <b-row class="align-items-center mb-3">
                  <b-col cols="8" class="ml-2">
                    <b-form @submit.prevent="onSubmit">
                      <b-form-input
                        v-model="partner"
                        size="sm"
                        required
                        autofocus
                        placeholder="Código do PN"
                      ></b-form-input>
                    </b-form>
                  </b-col>
                  <b-col cols="3"
                    ><b-icon
                      icon="search"
                      @click="onSubmit"
                      class="pointer text-poli"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-collapse>
          </div>
        </div>
      </b-nav>
    </div>

    <div>
      <div class="text-center">
        <small class="poweredby">POWERED BY</small>
        <img
          src="../assets/images/logo_yoobot_vertical.png"
          :style="{ width: '40%' }"
          class="center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MenuItems } from "@/utils/utils.js";
export default {
  data() {
    return {
      partner: "",
      menuitems: MenuItems,
      show: false,
    };
  },
  methods: {

    /**
     * Navigate to the page with the uri passed
     * If the Uri is 'login' the logout function is called
     * @param {string} uri
     */
    navigate(uri) {
      this.show = false;
      if (uri == "/login") {
        this.logout();
      }
      this.$router.push({
        path: uri,
      });
    },

    /**
     * Navigate to the page partnerbills with the paramater in partner variable 
     */
    onSubmit() {
      if (this.partner != "") {
        this.show = false;
        this.$router.push({
          name: "PartnerBills",
          params: { id: this.partner },
        });
      }
    },

    /**
     * Delete the sessionStorage token variable
     */
    logout() {
      sessionStorage.removeItem("token");
    },
  },
};
</script>

<style scoped>
.menu-card {
  min-width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 10px;
}
.poliedro-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 20px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.poweredby {
  color: #787878;
}


</style>
