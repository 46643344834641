<template>
  <div class="mt-3 mb-3 ml-2">
    <div class="d-flex justify-content-end">
      <b-button @click="SyncData" :disabled="this.$root.requesting" class="btn-poli">
        Sincronizar Dados
      </b-button>
    </div>
    <div>
      <p>Ultima sincronização: {{ this.updatedTime }}</p>
    </div>

    <b-modal
      v-model="loading"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
    >
      <div class="d-flex justify-content-center align-items-center text-load">
        <b-spinner label="Spinning"></b-spinner>
        <strong class="ml-2">Sincronizando Dados...</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      updatedTime: null,
    };
  },
  beforeMount() {
    this.updateSyncTime();
  },
  methods: {
    async SyncData() {
      this.$root.requesting = true;
      this.loading = true;
      try {
        const response = await this.$root.instance.post(
          "/Sync",
          {},
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.token,
            },
          }
        );
        console.log(response);
        if (response.status == 200) {
          await this.updateSyncTime();
          this.$root.requesting = false;
          this.loading = false;
          this.$emit("dataUpdated");
        } else {
          this.$root.requesting = false;
          this.loading = false;
        }
      } catch (err) {
        this.$root.requesting = false;
        this.loading = false;
      }
    },
    async updateSyncTime() {

      try {
        const response = await this.$root.instance.get("/SyncTime", {
          headers: {
            Authorization: "Bearer " + sessionStorage.token,
          },
        });

        if (response.status == 200) {
          console.log(response.data);
          this.updatedTime = response.data.lastUpdate;
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }

    },
  },
};
</script>

