import { render, staticRenderFns } from "./TablePartnerBills.vue?vue&type=template&id=574d200a&scoped=true&"
import script from "./TablePartnerBills.vue?vue&type=script&lang=js&"
export * from "./TablePartnerBills.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574d200a",
  null
  
)

export default component.exports