import Vue from 'vue'
import Router from 'vue-router'
import PartnerBills from '@/pages/PagePartnerBills'
import Login from '@/pages/PageLogin'
import Settings from '@/pages/PageSettings'
import BusinessPartners from '@/pages/PageBusinessPartners'
import Charts from '@/pages/PageCharts'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/partnerbills/:id',
            name: 'PartnerBills',
            component: PartnerBills,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: { guest: true },
        },
        {
            path: '/',
            name: 'BusinessPartners',
            component: BusinessPartners,
            meta: { requiresAuth: true }
        },
        {
            path: '/settings',
            name: 'Settings',
            component: Settings,
            meta: { requiresAuth: true }
        },
        {
            path: '/charts',
            name: 'Charts',
            component: Charts,
            meta: { requiresAuth: true }
        },
    ],
    mode: 'history',


})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (!sessionStorage.token) {
            next({
                name: "Login"
            })
        } else {


            next()

        }
    } else if (to.meta.guest && sessionStorage.token) {
        next({ name: "BusinessPartners" })
    } else {
        next()
    }
})

export default router