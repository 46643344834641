<template>
  <b-row :style="{height: '99vh'}" class="justify-content-center align-items-center">
      <CardLogin/>
  </b-row>
</template>

<script>
import CardLogin from "@/components/CardLogin";

export default {
  components: {
    CardLogin
  }}
</script>


