<template>
  <b-navbar
    toggleable
    type="light"
    variant="light"
    class="d-flex flex-column justify-content-between align-items-start"
  >
    <div class="d-flex flex-row justify-content-between align-items-center" :style="{width: '90vw'}" >
      <b-navbar-brand
        ><img
          src="../assets/images/logo_poliedro_horizontal.png"
          :style="{ width: '200px' }"
          class="center"
      /></b-navbar-brand>

      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle>
    </div>

    <b-collapse id="navbar-toggle-collapse" v-model="visible">
      <b-navbar-nav class="ml-auto">
        <div v-for="(item, index) in menuitems" :key="index">
          <b-nav-item
            v-if="item.name != 'PartnerBills'"
            class="my-2"
            @click="navigate(item.uri)"
            :active="$route.name === item.name"
          >
            <b-icon :icon="item.icon" />
            <b> &nbsp;&nbsp;&nbsp;{{ item.label }} </b>
          </b-nav-item>

          <b-nav-item
            v-else
            class="my-2"
            @click="setVisible"
            :active="$route.name === item.name"
          >
            <div class="d-flex align-items-center justify-content-between">
              <b-icon :icon="item.icon" />
              <b> &nbsp;&nbsp;&nbsp;{{ item.label }} </b>
              <b-form @submit.prevent="onSubmit">
                <b-form-input
                  v-model="partner"
                  :style="{ width: '100px' }"
                  size="sm"
                  class="ml-3"
                  required
                  autofocus
                  placeholder="Código do PN"
                ></b-form-input>
              </b-form>
              <b-icon
                icon="search"
                @click="onSubmit"
                class="pointer m-2"
                scale="1"
              />
            </div>
          </b-nav-item>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { MenuItems } from "@/utils/utils.js";

export default {
  data() {
    return {
      menuitems: MenuItems,
      visible: false,
      partner: "",
    };
  },
  methods: {
    /**
     * Navigate to the page with the uri passed
     * If the Uri is 'login' the logout function is called
     * @param {string} uri
     */
    navigate(uri) {
      this.visible = false;

      if (uri == "/login") {
        this.logout();
      }
      this.$router.push({
        path: uri,
      });
    },

    /**
     * Navigate to the page partnerbills with the paramater in partner variable
     */
    onSubmit() {
      if (this.partner != "") {
        this.visible = false;
        this.$router.push({
          name: "PartnerBills",
          params: { id: this.partner },
        });
      }
    },

    /**
     * Delete the sessionStorage token variable
     */
    logout() {
      sessionStorage.removeItem("token");
    },

    /**
     * Set the state visible of navBar
     */
    setVisible() {
      this.visible = true;
    },
  },
};
</script>