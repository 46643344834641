<template>
  <b-container class="border-poli rounded-lg shadow p-3 mb-5 mt-2 card-filter justify-content-center align-items-center">
    <b-row class="justify-content-center mb-2" :style="{ color: '#787878' }">
      <b-col>
        <b-icon icon="
receipt-cutoff" scale="2" />
      </b-col>
    </b-row>
    <b-row class="justify-content-center mb-1">
      <b-col class="d-flex justify-content-center">
        <b-dropdown
          text="Selecionar Contas"
          ref="dropdown"
          class="m-1 dropdown-poli"
          size="sm"
          variant="outline-primary"
          
        >
          <b-form-checkbox-group
            v-model="selected"
            :options="options"
            class="p-2 switch-poli"
            value-field="item"
            text-field="name"
            stacked
            switches
            size="sm"
            @change="onChange"
          ></b-form-checkbox-group>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row
      class="d-flex justify-content-center "
      :style="{ color: '#787878' }"
    >
      <b-col>Contas de Origem</b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      selected: this.$root.filters.accounts,
      options: [
        { item: '110201001', name: "Contas a Receber - 110201001" },
        { item: '110201003', name: "Contas a Receber - 110201003" },
        { item: '210604001', name: "Adiantamento - 210604001" },
      ],
    };
  },
  methods: {
    /**
      Change the account filter variable with the selecteds accounts in the variable selected
     */
    onChange() {
      this.$root.filters.accounts = this.selected;
    },
  },
};
</script>

