<template>
  <b-card  style="width: 85%" class="m-3 border-poli shadow">
    <h2 class="mb-3">Usuários Registrados:</h2>
    <b-list-group class="overflow-auto">
      <b-list-group-item
        v-for="user in users"
        :key="user.id"
        class="d-flex justify-content-between align-items-center"
        ><span>{{ user.email }}</span>
        <b-icon
          icon="trash"
          variant="danger"
          class="pointer"
          v-b-modal="user.email"
        />

        <b-modal :id="user.email" title="Aviso!" hide-footer>
          <p class="my-4">Deseja mesmo excluir o usuário {{ user.email }}?</p>
          <b-button
            class="mt-3"
            variant="danger"
            block
            @click="deleteUser(user.id,user.email)"
            >Exlcuir</b-button
          >
          <b-button
            class="mt-3"
            block
            @click="$bvModal.hide(user.email)"
            >Cancelar</b-button
          >
        </b-modal>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>

export default {
    props:{
        users: {
            required: true,
        }
    },
  methods: {

    /**
     * @param {string} id
     * @param {string} modal
     * 
     * Makes a request to delet the user with the passed id
     * 
     */
    async deleteUser(id,modal) {
      
        this.$root.requesting = true;
      try {
        const response = await this.$root.instance.delete("/User/" + id,{
          headers: {
            Authorization: "Bearer " + sessionStorage.token,
          },
        });

        if(response.status == 200){
            this.$emit('userDeleted')
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }  
      this.$bvModal.hide(modal)
      
        this.$root.requesting = false;
    },
  },
};
</script>
