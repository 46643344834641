<template>
  <div>
    <b-row>
      <h1 class="ml-4 mt-3">Configurações</h1>
    </b-row>
    <b-row>
      <b-col><FormChangePassword /></b-col>
      <b-col v-if="admin && users.length != 0"
        ><ListUser :users="users" @userDeleted="fetchUsers"
      /></b-col>
    </b-row>
    <b-row v-if="admin">
      <b-col>
        <FormCreateUser @userCreated="fetchUsers" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormChangePassword from "@/components/FormChangePassword";
import ListUser from "@/components/ListUser";
import FormCreateUser from "@/components/FormCreateUser";
export default {
  data() {
    return {
      admin: false,
      users: [],
    };
  },
  components: {
    FormChangePassword,
    ListUser,
    FormCreateUser,
  },
  created() {
    this.checkAdmin();
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    /**
     * Makes a request to get all users that are not admnistrator
     *
     */
    async fetchUsers() {
      this.$root.requesting = true;
      const response = await this.$root.instance.get("/User", {
        headers: {
          Authorization: "Bearer " + sessionStorage.token,
        },
      });
      if (response.status == 200) {
        this.users = response.data;
      }

      this.$root.requesting = false;
    },

    /**
     *  Makes a request to verify if the user is administrator
     *
     */
    async checkAdmin() {
      this.$root.requesting = true;
      try {
        const response = await this.$root.instance.get("/User/check", {
          headers: {
            Authorization: "Bearer " + sessionStorage.token,
          },
        });
        if (response.status == 200) {
          this.admin = true;
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }

      this.$root.requesting = false;
    },
  },
};
</script>
