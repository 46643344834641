<template>
  <div :class="this.customClass">
    <b-form-checkbox
      v-model="status"
      :disabled="this.$root.requesting"
      @change="this.rectify"
      :size="this.size"
     
    >
      {{this.label}}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
    },
    label:{
      required: false
    },
    rectified:{
      required: true
    },
    size:{
      required:false,
      default: "md"
    },
    customClass:{
      required: false,
      default: "pl-3 mb-4"
    }
  },
  data() {
    return {
      status: this.rectified,
      loading: false,
    };
  },
  watch: {
    rectified: function() {
      this.status = this.rectified
    }
  },
  methods: {
    async rectify() {
      this.loading = true;
      this.$root.requesting = true;
      await this.$root.instance.patch(
        "/BusinessPartner/Rectify/" + this.id,
        {},
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.token,
          },
        }
      );
      this.loading = false;
      this.$root.requesting = false;
    },
  },
};
</script>

<style>
</style>