<template>
  <b-row>
    <b-col cols="12">
      <b-row class="d-flex justify-content-between align-items-center">
        <b-col>
          <FilterReconciliation :id="id" :note="reconciliation_note" />
          <CheckRectified
            :id="id"
            :label="'Parceiro Retificado'"
            :rectified="rectified == 1 ? true : false"
          />
        </b-col>
        <b-col class="text-center">
          <FilterAccounts />
        </b-col>
        <b-col class="text-center">
          <FilterBranch />
        </b-col>
        <b-col class="text-center">
          <FilterCalendar />
        </b-col>
        <b-col class="text-center">
          <ViewerBalance :value="totalBalance" />
        </b-col>
      </b-row>

      <b-row class="justify-content-center align-items-center">
        <b-col cols="12" class="justify-content-center align-items-center">
          <TablePartnerBills :bills="bills" :busy="loading" />
          <b-button
            class="mb-3 btn-poli"
            variant="primary"
            @click="exportTable"
            :disabled="this.$root.requesting"
            >Exportar Tabela</b-button
          >
        </b-col>
      </b-row>
    </b-col>

    <b-modal
      v-model="loadingExcel"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
    >
      <div class="d-flex justify-content-center align-items-center text-load">
        <b-spinner label="Spinning"></b-spinner>
        <strong class="ml-2">Gerando arquivo...</strong>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import FilterBranch from "@/components/FilterBranch";
import FilterCalendar from "@/components/FilterCalendar";
import FilterReconciliation from "@/components/FilterReconciliation";
import ViewerBalance from "@/components/ViewerBalance";
// import FilterBusinessPartner from "@/components/FilterBusinessPartner";
// import FilterBadges from "@/components/FilterBadges";
import FilterAccounts from "@/components/FilterAccounts";
import TablePartnerBills from "@/components/TablePartnerBills";
import CheckRectified from "@/components/CheckRectified";
import FileDownload from "js-file-download";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      rectified: false,
      reconciliation_note: null,
      bills: [],
      loadingExcel: false,
    };
  },
  components: {
    TablePartnerBills,
    FilterBranch,
    FilterCalendar,
    FilterReconciliation,
    ViewerBalance,
    // FilterBusinessPartner,
    // FilterBadges,
    FilterAccounts,
    CheckRectified,
  },
  created() {
    //this.fetchPartnerBill();
    //this.fecthAdiantmentBill();
    this.getBills();
  },
  watch: {
    /**
     *
     * Call the fetchPartnerBill and fetchAdiantmentBill function
     */
    id: function () {
      this.getBills();
    },

    branchesFilter: function () {
      this.getBills();
    },
    initialDateFilter: function () {
      this.getBills();
    },
    endDateFilter: function () {
      this.getBills();
    },
    accountsFilter: function () {
      this.getBills();
    },
    reconciliationFilter: function () {
      this.getBills();
    },
  },
  computed: {
    branchesFilter() {
      return this.$root.filters.branches;
    },

    initialDateFilter() {
      return this.$root.filters.initialDate;
    },

    endDateFilter() {
      return this.$root.filters.endDate;
    },

    accountsFilter() {
      return this.$root.filters.accounts;
    },

    reconciliationFilter() {
      return this.$root.filters.reconciliation;
    },

    /**
     * Sum all partner_bills and adiantment_bills value of all elements in array filteredBills
     * @returns {number}
     */
    totalBalance() {
      var value = 0;

      this.bills.forEach((bills) => {
        value += bills.value;
      });
      return value;
    },
  },
  methods: {
    async getBills() {
      this.bills = [];
      this.loading = true;
      this.$root.requesting = true;

      try {
        const response = await this.$root.instance.get(
          "/BusinessPartner/GetNote/" + this.id,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.token,
            },
          }
        );

        if (response.status == 200) {
          console.log(response.data);
          this.rectified = response.data.rectified;
          this.reconciliation_note = response.data.reconciliation_note;
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }

      try {
        const response = await this.$root.instance.get(
          "/AdiantmentBill/filter/" + this.id,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.token,
            },
            params: {
              initialDate: this.$root.filters.initialDate,
              endDate: this.$root.filters.endDate,
              branch: this.$root.filters.branches,
              accounts: this.$root.filters.accounts,
              reconciliation: this.$root.filters.reconciliation,
            },
          }
        );

        if (response.status == 200) {
          console.log(response.data);
          this.bills = response.data.adiantment_bills;
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }

      try {
        const response = await this.$root.instance.get(
          "/PartnerBill/filter/" + this.id,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.token,
            },
            params: {
              initialDate: this.$root.filters.initialDate,
              endDate: this.$root.filters.endDate,
              branch: this.$root.filters.branches,
              accounts: this.$root.filters.accounts,
              reconciliation: this.$root.filters.reconciliation,
            },
          }
        );

        if (response.status == 200) {
          this.bills = this.bills.concat(response.data.partner_bills);
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }
      this.loading = false;
      this.$root.requesting = false;
    },

    async exportTable() {
      this.loadingExcel = true;
      this.$root.requesting = true;

      const response = await this.$root.instance.get(
        "/Excel/PartnerPage/" + this.id,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Disposition":
              "attachment; filename=lancamentos-contabeis-" + this.id + ".xlsx",

            Authorization: "Bearer " + sessionStorage.token,
          },
          params: {
            initialDate: this.$root.filters.initialDate,
            endDate: this.$root.filters.endDate,
            branch: this.$root.filters.branches,
            accounts: this.$root.filters.accounts,
            reconciliation: this.$root.filters.reconciliation,
          },
          responseType: "blob",
        }
      );

      if (response.status == 200) {
        FileDownload(
          response.data,
          "lancamentos-contabeis-" + this.id + ".xlsx"
        );
      }

      this.$root.requesting = false;
      this.loadingExcel = false;
    },
  },
};
</script>