<template>
  <div>
    <b-table
      striped
      hover
      responsive
      sort-icon-left
      sticky-header="65vh"
      thead-class="text-center"
      :items="bills"
      :fields="fields"
      bordered
      class="shadow border-poli"
      :busy="busy"
      empty-text = "Nenhum conteúdo a ser exibido"
      tbody-class = "text-center"

      show-empty
    >
      <template v-slot:cell(id)="row">
        {{formatId(row.item.id)}}
      </template>
      
      <template v-slot:cell(value)="row">
        <div
          :class="{
            'text-danger': Number(row.item.value).toFixed(2) < 0,
            'text-success': Number(row.item.value).toFixed(2) >= 0,
          }"
        >
          {{ formatValue(row.item.value) }}
        </div>
      </template>

      <template v-slot:cell(post_date)="row">
        {{ formatDate(row.item.post_date) }}
      </template>

      <template #table-busy>
        <div class="text-center text-load my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    bills: {
      required: true,
      type: Array,
    },
    busy: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "post_date",
          label: "Data",
          sortable: true,
        },
        {
          key: "id",
          label: "Nº da Transação Contábil",
          sortable: true,
        },

        {
          key: "origin_account",
          label: "Conta de Origem",
          sortable: true,
        },
        {
          key: "offset_account",
          label: "Conta Contábil de Contrapartida",
          sortable: true,
        },
        {
          key: "value",
          label: "Valor",
        },
        {
          key: "note",
          label: "Observação",
        },
        {
          key: "branch",
          label: "Filial",
          sortable: true,
        },

        {
          key: "reconciliation_number",
          label: "Nº de Reconciliação",
          sortable: true,
        },
      ],
      fileName: "oi",
    };
  },
  methods: {
     /**
     * 
     * Format value to show like money
     * @param {number} value
     */
    formatValue(value) {
      if (value != null) {
        if (Number(value).toFixed(2) == 0) value = 0;
        value =
          "R$ " +
          Number(value)
            .toFixed(2)
            .replace(".", ",")
            // eslint-disable-next-line
            .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");

        return value;
      }
    },

    /**
     * 
     * Format date to show like dd/MM/yyyy
     * @param {string} date
     */
    formatDate(date) {
      date =
        date.substring(8, 10) +
        "/" +
        date.substring(5, 7) +
        "/" +
        date.substring(0, 4);
      return date;
    },

    formatId(id){
      return id.substr(0, id.indexOf('_')); 
    }
  },
};
</script>

<style scoped>
</style>