<template>
  <div>
    <div v-if="!$route.meta.guest">
      <div v-if="windowWidth < 550">
        <TheNavBar />
        <b-container>
          <router-view :key="this.$router.path" />
        </b-container>
      </div>

      <div v-else>
        <div class="menu">
          <TheSidebar />
        </div>
        <div id="__main">
          <b-container>
            <router-view :key="this.$router.path" />
          </b-container>
        </div>
      </div>
    </div>

    <div v-else>
      <b-container>
        <router-view :key="this.$router.path" />
      </b-container>
    </div>

  
  </div>
</template>

<script>
import TheSidebar from "../src/components/TheSidebar";
import TheNavBar from "../src/components/TheNavBar";
export default {
  components: {
    TheSidebar,
    TheNavBar,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

@import "../src/assets/styles/Variants.css";

body {
  overflow-x: hidden;
}
#app {
  font-family: "Roboto", sans-serif;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
}

#__main {
  margin-left: 250px;
}
.card-filter {
  max-width: 230px;
  height: 142px;
}

/**Vue Transitions */
.slide-fade-enter-active {
  transition: 0.3s ease-in-out;
}
.slide-fade-leave-active {
  transition: 0.3s ease-in-out;
}
.slide-fade-leave-to {
  transform: translateX(-5px);
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(5px);
  opacity: 0;
}

.pointer {
  cursor: pointer;
}


</style>
