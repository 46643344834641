<template>
  <b-container
    v-if="partnersFiltered.length != 0"
    class="p-1 m-2 justify-content-start align-items-center"
  >
    <b-row>
      <b-col>
        <b-icon icon="funnel" />
        <b-badge
          v-for="partner in partnersFiltered"
          :key="partner"
          class="m-2 badges"
          variant="info"
          >{{ partner }}
          <b-icon @click="deleteFilter(partner)" icon="x" class="pointer"
        /></b-badge>
        <b-icon
          icon="trash"
          variant="danger"
          @click="cleanFilter"
          class="pointer"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    partnersFiltered() {
      return this.$root.filters.partners;
    },
  },
  methods: {
    /**
     * Attribute a void array to the partners filter variable
     */
    cleanFilter() {
      this.$root.filters.partners = [];
    },

    /**
     * Delete the partner passed from paramaters of partners filter array
     * @param {sting} partner id partner
     */
    deleteFilter(partner) {
      this.$root.filters.partners = this.$root.filters.partners.filter(
        function (el) {
          return el != partner;
        }
      );
    },
  },
};
</script>