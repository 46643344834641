export const MenuItems = [
    {
        label: 'Dashboard',
        name: 'BusinessPartners',
        uri: '/',
        icon: 'grid1x2',
        color: 'primary'
    },
    {
        label: 'Gráficos',
        name: 'Charts',
        uri: '/charts',
        icon: 'bar-chart',
        color: 'primary'
    },
    {
        label: 'Parceiros de Negócio',
        name: 'PartnerBills',
        uri: '',
        icon: 'person',
        color: 'primary'

    },

    {
        label: 'Configurações',
        name: 'Settings',
        uri: '/settings',
        icon: 'gear',
        color: 'danger'

    }, {
        label: 'Sair',
        name: 'Login',
        uri: '/login',
        icon: 'box-arrow-in-left',
        color: 'danger'

    },
];