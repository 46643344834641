<template>
  <b-container
    class="border-poli rounded-lg shadow p-3 mb-5 mt-2 card-filter justify-content-center align-items-center"
  >
  <b-row class="justify-content-center mb-3" :style="{ color: '#787878' }">
    <b-col>
      <b-icon icon="person-bounding-box"  scale="2"/>
    </b-col>
  </b-row>
    <b-row class="justify-content-center mb-1 ">
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-input
            v-model="businesspartner"
            placeholder="Código do PN"
            size="sm"
            :disabled="this.$root.requesting"
          ></b-form-input>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="justify-content-center text-center"
      :style="{ color: '#787878' }">
    <b-col class="mb-2">
      Filtrar Parceiro
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      businesspartner: "",
    };
  },
  methods: {

    /**
     * Add the partner in businesspartner variable to the variable partners filter array
     */
    onSubmit(event) {
      event.preventDefault();
      if (!this.$root.filters.partners.includes(this.businesspartner))
        this.$root.filters.partners.push(this.businesspartner);
      this.businesspartner = "";
    },
  },
};
</script>