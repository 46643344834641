// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMask from 'v-mask'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
//import https from 'https'
import Highcharts from 'highcharts';
import VueHighcharts from 'vue-highcharts'
import exportingInit from 'highcharts/modules/exporting'
import accessibilityInit from 'highcharts/modules/accessibility'
import drilldownInit from 'highcharts/modules/drilldown'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMask)

Vue.use(VueHighcharts)
exportingInit(Highcharts)
accessibilityInit(Highcharts)
drilldownInit(Highcharts)

// new Vue({
//     el: '#app',
//     router,
//     template: '<App/>',
//     components: { App },

//     data: {
//         filters: {
//             branches: [],
//             initialDate: '',
//             endDate: '',
//             partners: [],
//             reconciliation: null,
//             accounts: [],

//         },
//         requesting: false,
//         workedTablePages: [],
//         businessPartnerItems: [],
//         instance: axios.create({
//             baseURL: process.env.API_URL + '/api',
//             // httpsAgent: new https.Agent({
//             //     rejectUnauthorized: false,
//             //     keepAlive: true
//             // })
//         })
//     },
// })


new Vue({
    router,
    render: h => h(App),
    data: {
        filters: {
            branches: [],
            initialDate: '',
            endDate: '',
            partners: [],
            reconciliation: null,
            accounts: [],

        },
        requesting: false,
        workedTablePages: [],
        businessPartnerItems: [],
        instance: axios.create({
            baseURL: process.env.VUE_APP_BASE_URL,
            timeout: 900000
            // httpsAgent: new https.Agent({
            //     rejectUnauthorized: false,
            //     keepAlive: true
            // })
        })
    }
}).$mount('#app')