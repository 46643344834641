<template>
  <b-card :style="{ maxWidth: '300px' }" class="border-poli shadow">
    <img
      src="@/assets/images/logo_poliedro.jpg"
      :style="{ width: '80%', marginBottom: '20px' }"
      class="center"
    />

    <b-form @submit.prevent="onSubmit">
      <b-form-group label="E-mail:" label-for="login">
        <b-form-input
          v-model="email"
          id="login"
          type="email"
          autofocus
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Senha:" label-for="password">
        <b-form-input
          v-model="password"
          id="password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>
      <small v-if="loginResponse != ''" class="text-danger ml-1">{{
        loginResponse
      }}</small>
      <b-button type="submit" block class="mt-3 btn-poli" :disabled="this.$root.requesting" >Entrar</b-button>
    </b-form>
    <div class="text-center mt-3">
      <small class="poweredby">POWERED BY</small>
      <img
        src="@/assets/images/logo_yoobot_horizontal.png"
        :style="{ width: '40%' }"
        class="center"
      />
    </div>
  </b-card>
</template>

<script>

export default {
  data() {
    return {
      email: "",
      password: "",
      loginResponse: "",
    };
  },
  methods: {
    /**
     * Send the form data to log in
     */
    async onSubmit() {
      this.$root.requesting = true;
      try {
        const response = await this.$root.instance.post("/login", {
          email: this.email,
          password: this.password,
        });

        if (response.status == 200) {
          this.loginResponse = "";
          sessionStorage.token = response.data.token;
          this.$root.isAuthenticated = true;
          this.$router.push({
            path: "/",
          });
        }
      } catch (err) {
        this.loginResponse = "E-mail ou senha incorretos.";
      }

      this.$root.requesting = false;
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.poweredby {
  color: #787878;
}
</style>
