<template>
  <b-card style="width: 85%" class="m-3 border-poli shadow">
    <h2 class="mb-3">Alterar Senha:</h2>
    <b-form @submit.prevent="updatePassword" class="mb-3">
      <b-form-group
        label="Senha antiga:"
        label-for="old-password"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="7"
      >
        <b-form-input
          id="old-password"
          type="password"
          v-model="oldPassword"
          :disabled="this.$root.requesting"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Nova senha:"
        label-for="new-password"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="7"
      >
        <b-form-input
          id="new-password"
          type="password"
          v-model="newPassword"
          :disabled="this.$root.requesting"
          :state="state"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Repita a nova senha:"
        label-for="renew-password"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="7"
        invalid-feedback="As senhas não coincidem"
        
      >
        <b-form-input
          id="renew-password"
          type="password"
          v-model="renewPassword"
          :disabled="this.$root.requesting"

          :state="state"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" :disabled="disabled || this.$root.requesting" class="btn-poli"  
        >Alterar</b-button
      >
    </b-form>
    <small
      class="mt-4 ml-1"
      :class="{
        'text-danger': answer.includes('não'),
        'text-success': answer.includes('Sucesso'),
      }"
      v-if="answer != ''"
      >{{ answer }}</small
    >
  </b-card>
</template>

<script>

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      renewPassword: "",
      answer: "",
    };
  },
  methods: {
    /**
     * If the variables oldPassword is not void and the newPassword is the same that renew Password
     * makes a request to change the password
     */
    async updatePassword() {
      this.$root.requesting = true;
      if (this.state === true && this.oldPassword != "") {
        try {
          const response = await this.$root.instance.patch(
            "/User/update",
            { oldPassword: this.oldPassword, newPassword: this.newPassword },
            {
              headers: {
                Authorization: "Bearer " + sessionStorage.token,
              },
            }
          );
          if (response.status == 200) {
            this.answer = "Senha Alterada com Sucesso!";
          }
        } catch (err) {
          this.answer = "Senha Antiga não confere.";
        }
      }

      this.$root.requesting = false;
    },
  },
  computed: {
    /**
     * Return a boolean if the renewPassword equals newPassword
     * @returns {boolean}
     */
    state() {
      if (this.renewPassword != "") {
        return this.renewPassword == this.newPassword;
      }
      return null;
    },

    /**
     * Return a boolean if the form is completed
     * @returns {boolean}
     */
    disabled() {
      if (this.state != null) return !this.state || this.oldPassword === "";
      return true;
    },
  },
};
</script>