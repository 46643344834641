<template>
  <div class="ml-2 mr-2">
    <b-table
      striped
      id="my-table"
      :per-page="perPage"
      :current-page="currentPage"
      hover
      bordered
      sort-icon-left
      responsive
      thead-class="text-center align-middle"
      sticky-header="59vh"
      :items="items"
      :fields="filteredFields"
      class="shadow border-poli"
      :busy="busy"
      empty-text="Nenhum conteúdo a ser exibido"
      tbody-class="text-center"
      show-empty
    >
      <template v-slot:cell(id)="row">
        <router-link
          class="router-poli"
          :to="{ name: 'PartnerBills', params: { id: row.item.id } }"
        >
          {{ row.item.id }}
        </router-link>
      </template>

      <template v-slot:cell(rectified)="row">
        <CheckRectified
          :id="row.item.id"
          :rectified="row.item.rectified == 1 ? true : false"
          size="lg"
          customClass="ml-2 pl-1"
        />
      </template>

      <template v-slot:cell(reconciliation_note)="row">
        <CardRobotLogs v-if="row.item.reconciliation_note"
          :robotNote="JSON.parse(JSON.parse(row.item.reconciliation_note))"
        />
      </template>

      <template v-slot:cell(partnerBills)="row">
        <div
          :class="{
            'text-danger': Number(row.item.partnerBills).toFixed(2) < 0,
            'text-success': Number(row.item.partnerBills).toFixed(2) >= 0,
          }"
        >
          {{ formatValue(row.item.partnerBills) }}
        </div>
      </template>

      <template v-slot:cell(adiantmentBills)="row">
        <div
          :class="{
            'text-danger': Number(row.item.adiantmentBills).toFixed(2) < 0,
            'text-success': Number(row.item.adiantmentBills).toFixed(2) >= 0,
          }"
        >
          {{ formatValue(row.item.adiantmentBills) }}
        </div>
      </template>

      <template v-slot:cell(LIV1)="row">
        <div
          :class="{
            'text-danger': row.item.LIV1.includes('ERRO'),
            'text-success': !row.item.LIV1.includes('ERRO'),
          }"
        >
          {{ row.item.LIV1 }}
        </div>
      </template>

      <template v-slot:cell(EDI1)="row">
        <div
          :class="{
            'text-danger': row.item.EDI1.includes('ERRO'),
            'text-success': !row.item.EDI1.includes('ERRO'),
          }"
        >
          {{ row.item.EDI1 }}
        </div>
      </template>

      <template v-slot:cell(EDI6)="row">
        <div
          :class="{
            'text-danger': row.item.EDI6.includes('ERRO'),
            'text-success': !row.item.EDI6.includes('ERRO'),
          }"
        >
          {{ row.item.EDI6 }}
        </div>
      </template>

      <template v-slot:cell(CUR1)="row">
        <div
          :class="{
            'text-danger': row.item.CUR1.includes('ERRO'),
            'text-success': !row.item.CUR1.includes('ERRO'),
          }"
        >
          {{ row.item.CUR1 }}
        </div>
      </template>

      <template v-slot:cell(NAS1)="row">
        <div
          :class="{
            'text-danger': row.item.NAS1.includes('ERRO'),
            'text-success': !row.item.NAS1.includes('ERRO'),
          }"
        >
          {{ row.item.NAS1 }}
        </div>
      </template>

      <template v-slot:cell(MMI1)="row">
        <div
          :class="{
            'text-danger': row.item.MMI1.includes('ERRO'),
            'text-success': !row.item.MMI1.includes('ERRO'),
          }"
        >
          {{ row.item.MMI1 }}
        </div>
      </template>

      <template v-slot:cell(ALO1)="row">
        <div
          :class="{
            'text-danger': row.item.ALO1.includes('ERRO'),
            'text-success': !row.item.ALO1.includes('ERRO'),
          }"
        >
          {{ row.item.ALO1 }}
        </div>
      </template>

      <template v-slot:cell(COL1)="row">
        <div
          :class="{
            'text-danger': row.item.COL1.includes('ERRO'),
            'text-success': !row.item.COL1.includes('ERRO'),
          }"
        >
          {{ row.item.COL1 }}
        </div>
      </template>

      <template v-slot:cell(SPO1)="row">
        <div
          :class="{
            'text-danger': row.item.SPO1.includes('ERRO'),
            'text-success': !row.item.SPO1.includes('ERRO'),
          }"
        >
          {{ row.item.SPO1 }}
        </div>
      </template>

      <template v-slot:cell(SPO2)="row">
        <div
          :class="{
            'text-danger': row.item.SPO2.includes('ERRO'),
            'text-success': !row.item.SPO2.includes('ERRO'),
          }"
        >
          {{ row.item.SPO2 }}
        </div>
      </template>

      <template v-slot:cell(CAM1)="row">
        <div
          :class="{
            'text-danger': row.item.CAM1.includes('ERRO'),
            'text-success': !row.item.CAM1.includes('ERRO'),
          }"
        >
          {{ row.item.CAM1 }}
        </div>
      </template>

      <template v-slot:cell(CAM2)="row">
        <div
          :class="{
            'text-danger': row.item.CAM2.includes('ERRO'),
            'text-success': !row.item.CAM2.includes('ERRO'),
          }"
        >
          {{ row.item.CAM2 }}
        </div>
      </template>

      <template v-slot:cell(LIV3)="row">
        <div
          :class="{
            'text-danger': row.item.LIV3.includes('ERRO'),
            'text-success': !row.item.LIV3.includes('ERRO'),
          }"
        >
          {{ row.item.LIV3 }}
        </div>
      </template>

      <template v-slot:cell(COL2)="row">
        <div
          :class="{
            'text-danger': row.item.COL2.includes('ERRO'),
            'text-success': !row.item.COL2.includes('ERRO'),
          }"
        >
          {{ row.item.COL2 }}
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-load my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
    </b-table>

    <div class="d-flex justify-content-between mb-2">
      <div class="d-flex">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          class="pagination-poli"
          :disabled="this.$root.requesting"
        >
        </b-pagination>
        <div
          class="text-center my-1 ml-2 load-new-data sm"
          v-if="loadingPartners && !busy"
        >
          <b-spinner class="align-middle" small></b-spinner>
          <strong>Carregando dados...</strong>
        </div>
      </div>

      <div>
        <b-dropdown
          right
          dropup
          text="Exportar"
          class="dropgroup-poli"
          :disabled="this.$root.requesting"
        >
          <b-dropdown-item @click="exportAllPartners"
            >Exportar todos os parceiros</b-dropdown-item
          >
          <b-dropdown-item @click="exportFilteredPartners"
            >Exportar parceiros filtrados</b-dropdown-item
          >
          <b-dropdown-item @click="exportAllLCs"
            >Exportar todos os lançamentos contábeis</b-dropdown-item
          >
          <b-dropdown-item @click="exportAdiantmentLCs"
            >Exportar lançamentos contábeis de Adiantamento</b-dropdown-item
          >
          <b-dropdown-item @click="exportPartnerLCs"
            >Exportar lançamentos contábeis de Contas a Receber</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <b-modal
      v-model="loading"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
    >
      <div class="d-flex justify-content-center align-items-center text-load">
        <b-spinner label="Spinning"></b-spinner>
        <strong class="ml-2">Gerando arquivo...</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FileDownload from "js-file-download";
import CheckRectified from "@/components/CheckRectified";
import CardRobotLogs from "@/components/CardRobotLogs";
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    busy: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    CheckRectified,
    CardRobotLogs,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      loading: false,
      loadingPartners: false,
    };
  },
  computed: {
    /**
     * Return the items array length
     * @returns {number}
     */
    rows() {
      return this.items.length;
    },

    filteredFields() {
      let fields = [
        {
          key: "rectified",
          label: "Retificado",
          sortable: true,
        },
        {
          key: "id",
          label: "Código",
          sortable: true,
        },
        {
          key: "name",
          label: "Nome",
          sortable: true,
        },
        {
          key: "reconciliation_note",
          label: "Nota de Reconciliação",
          sortable: true,
        },
        {
          key: "partnerBills",
          label: "Saldo Conta do Cliente",
          sortable: false,
        },
        {
          key: "adiantmentBills",
          label: "Saldo Conta de Adiantamento",
          sortable: false,
        },
      ];
      if (this.$root.filters.branches.length != 0) {
        fields.splice(3, 1);
        this.$root.filters.branches.forEach((el) => {
          fields.push({ key: el, label: el, sortable: true });
        });
      }
      return fields;
    },
  },
  watch: {
    /**
     * Verify if the table page is already added to the variable workedTablePages
     * If not , the page is inserted, and call the methos insertNewData()
     */
    currentPage: function () {
      if (this.rows / this.perPage == this.currentPage) {
        this.$root.workedTablePages.push(this.currentPage);
        this.insertNewData();
      }
    },
  },
  methods: {
    /**
     *
     * Format value to show like money
     * @param {number} value
     */
    formatValue(value) {
      if (value != null) {
        if (Number(value).toFixed(2) == 0) value = 0;
        value =
          "R$ " +
          Number(value)
            .toFixed(2)
            .replace(".", ",")
            // eslint-disable-next-line
            .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return value;
      }
    },

    /**
     * Format note, deleting the word 'Erro:'
     * @param {string} note
     */
    formatNote(note) {
      if (note != null) return note.replace("Erro:", "");
    },

    formatRectified(rectified) {
      if (rectified == 1) return "Sim";
      else return "Não";
    },

    /**
     * Makes a request to get partners, passing an offset like the page number and a limit like twice the
     * page number. After this, verify if the getted partners is already in the variable
     * businessPartnerItems. If not, the partner is inserted.
     */
    async insertNewData() {
      this.loadingPartners = true;
      this.$root.requesting = true;
      const response = await this.$root.instance.get(
        "/BusinessPartner/filter",
        {
          headers: {
            Offset:
              (this.$root.workedTablePages.length - 1) * this.perPage * 10,
            Limit: this.perPage * 10,
            Authorization: "Bearer " + sessionStorage.token,
          },
          params: {
            businessPartners: this.$root.filters.partners,
            initialDate: this.$root.filters.initialDate,
            endDate: this.$root.filters.endDate,
            branch: this.$root.filters.branches,
          },
        }
      );
      if (response.status == 200) {
        response.data.forEach((el) => {
          this.$root.businessPartnerItems.push(el);
        });
      }

      this.loadingPartners = false;
      this.$root.requesting = false;
    },

    async exportAllPartners() {
      this.loading = true;

      this.$root.requesting = true;
      const response = await this.$root.instance.get("/Excel/All", {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Disposition": "attachment; filename=parceiros.xlsx",
          Authorization: "Bearer " + sessionStorage.token,
        },
        responseType: "blob",
      });

      if (response.status == 200) {
        FileDownload(response.data, "parceiros.xlsx");
      }

      this.loading = false;
      this.$root.requesting = false;
    },

    async exportFilteredPartners() {
      this.$root.requesting = true;
      this.loading = true;
      const response = await this.$root.instance.get("/Excel/Filtered", {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Disposition":
            "attachment; filename=parceiros-filtrados.xlsx",

          Authorization: "Bearer " + sessionStorage.token,
        },
        params: {
          businessPartners: this.$root.filters.partners,
          initialDate: this.$root.filters.initialDate,
          endDate: this.$root.filters.endDate,
          branch: this.$root.filters.branches,
        },
        responseType: "blob",
      });

      if (response.status == 200) {
        FileDownload(response.data, "parceiros-filtrados.xlsx");
      }

      this.loading = false;

      this.$root.requesting = false;
    },

    async exportAllLCs() {
      this.$root.requesting = true;
      this.loading = true;

      const response = await this.$root.instance.get("/Excel/AllBills", {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Disposition":
            "attachment; filename=lancamentos-contabeis.xlsx",

          Authorization: "Bearer " + sessionStorage.token,
        },
        params: {
          businessPartners: this.$root.filters.partners,
          initialDate: this.$root.filters.initialDate,
          endDate: this.$root.filters.endDate,
          branch: this.$root.filters.branches,
        },
        responseType: "blob",
      });

      if (response.status == 200) {
        FileDownload(response.data, "lancamentos-contabeis.xlsx");
      }

      this.loading = false;
      this.$root.requesting = false;
    },

    async exportAdiantmentLCs() {
      this.$root.requesting = true;
      this.loading = true;

      const response = await this.$root.instance.get("/Excel/AdiantmentBills", {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Disposition":
            "attachment; filename=lancamentos-contabeis-adiantamento.xlsx",

          Authorization: "Bearer " + sessionStorage.token,
        },
        params: {
          businessPartners: this.$root.filters.partners,
          initialDate: this.$root.filters.initialDate,
          endDate: this.$root.filters.endDate,
          branch: this.$root.filters.branches,
        },
        responseType: "blob",
      });

      if (response.status == 200) {
        FileDownload(response.data, "lancamentos-contabeis.xlsx");
      }

      this.loading = false;
      this.$root.requesting = false;
    },

    async exportPartnerLCs() {
      this.$root.requesting = true;
      this.loading = true;

      const response = await this.$root.instance.get("/Excel/PartnerBills", {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Disposition":
            "attachment; filename=lancamentos-contabeis-adiantamento.xlsx",

          Authorization: "Bearer " + sessionStorage.token,
        },
        params: {
          businessPartners: this.$root.filters.partners,
          initialDate: this.$root.filters.initialDate,
          endDate: this.$root.filters.endDate,
          branch: this.$root.filters.branches,
        },
        responseType: "blob",
      });

      if (response.status == 200) {
        FileDownload(
          response.data,
          "lancamentos-contabeis-contas-receber.xlsx"
        );
      }

      this.loading = false;
      this.$root.requesting = false;
    },
  },
};
</script>

<style >
.table thead th {
  vertical-align: middle !important;
}
</style>