<template>
  <b-container
    class="border-poli unoverflow rounded-lg shadow p-3 mb-5 mt-2 card-filter justify-content-center align-items-center"
  >
    <b-row class="justify-content-center mb-3" :style="{ color: '#787878' }">
      <b-col>
        <b-icon icon="wallet2" scale="2" />
      </b-col>
    </b-row>
    <b-row
      class="justify-content-center mb-1"
      :class="{
        'text-success': Number(value).toFixed(2) >= 0,
        'text-danger': Number(value).toFixed(2) < 0,
      }"
      :style="{ fontWeight: 'bold' }"
    >
      <b-col>
        <span > {{ money }} </span>
      </b-col>
    </b-row>
    <b-row
      class="justify-content-center text-center"
      :style="{ color: '#787878' }"
    >
      <b-col> Saldo Total </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  computed: {

    /**
     * Format the value variable to show like money
     */
    money() {
      if(Number(this.value).toFixed(2) == 0){
        const amount = parseFloat(Math.abs(this.value))
        .toFixed(2)
        .replace(".", ",")
        // eslint-disable-next-line
        .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return 'R$ '+ amount
      }
      const amount =Number(this.value)
        .toFixed(2)
        .replace(".", ",")
        // eslint-disable-next-line
        .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return 'R$ '+ amount
    },
  }
};
</script>

<style scoped>

.unoverflow{
  min-width: 100px;
}
</style>


