var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-2 mr-2"},[_c('b-table',{staticClass:"shadow border-poli",attrs:{"striped":"","id":"my-table","per-page":_vm.perPage,"current-page":_vm.currentPage,"hover":"","bordered":"","sort-icon-left":"","responsive":"","thead-class":"text-center align-middle","sticky-header":"59vh","items":_vm.items,"fields":_vm.filteredFields,"busy":_vm.busy,"empty-text":"Nenhum conteúdo a ser exibido","tbody-class":"text-center","show-empty":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(row){return [_c('router-link',{staticClass:"router-poli",attrs:{"to":{ name: 'PartnerBills', params: { id: row.item.id } }}},[_vm._v(" "+_vm._s(row.item.id)+" ")])]}},{key:"cell(rectified)",fn:function(row){return [_c('CheckRectified',{attrs:{"id":row.item.id,"rectified":row.item.rectified == 1 ? true : false,"size":"lg","customClass":"ml-2 pl-1"}})]}},{key:"cell(reconciliation_note)",fn:function(row){return [(row.item.reconciliation_note)?_c('CardRobotLogs',{attrs:{"robotNote":JSON.parse(JSON.parse(row.item.reconciliation_note))}}):_vm._e()]}},{key:"cell(partnerBills)",fn:function(row){return [_c('div',{class:{
          'text-danger': Number(row.item.partnerBills).toFixed(2) < 0,
          'text-success': Number(row.item.partnerBills).toFixed(2) >= 0,
        }},[_vm._v(" "+_vm._s(_vm.formatValue(row.item.partnerBills))+" ")])]}},{key:"cell(adiantmentBills)",fn:function(row){return [_c('div',{class:{
          'text-danger': Number(row.item.adiantmentBills).toFixed(2) < 0,
          'text-success': Number(row.item.adiantmentBills).toFixed(2) >= 0,
        }},[_vm._v(" "+_vm._s(_vm.formatValue(row.item.adiantmentBills))+" ")])]}},{key:"cell(LIV1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.LIV1.includes('ERRO'),
          'text-success': !row.item.LIV1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.LIV1)+" ")])]}},{key:"cell(EDI1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.EDI1.includes('ERRO'),
          'text-success': !row.item.EDI1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.EDI1)+" ")])]}},{key:"cell(EDI6)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.EDI6.includes('ERRO'),
          'text-success': !row.item.EDI6.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.EDI6)+" ")])]}},{key:"cell(CUR1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.CUR1.includes('ERRO'),
          'text-success': !row.item.CUR1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.CUR1)+" ")])]}},{key:"cell(NAS1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.NAS1.includes('ERRO'),
          'text-success': !row.item.NAS1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.NAS1)+" ")])]}},{key:"cell(MMI1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.MMI1.includes('ERRO'),
          'text-success': !row.item.MMI1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.MMI1)+" ")])]}},{key:"cell(ALO1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.ALO1.includes('ERRO'),
          'text-success': !row.item.ALO1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.ALO1)+" ")])]}},{key:"cell(COL1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.COL1.includes('ERRO'),
          'text-success': !row.item.COL1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.COL1)+" ")])]}},{key:"cell(SPO1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.SPO1.includes('ERRO'),
          'text-success': !row.item.SPO1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.SPO1)+" ")])]}},{key:"cell(SPO2)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.SPO2.includes('ERRO'),
          'text-success': !row.item.SPO2.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.SPO2)+" ")])]}},{key:"cell(CAM1)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.CAM1.includes('ERRO'),
          'text-success': !row.item.CAM1.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.CAM1)+" ")])]}},{key:"cell(CAM2)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.CAM2.includes('ERRO'),
          'text-success': !row.item.CAM2.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.CAM2)+" ")])]}},{key:"cell(LIV3)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.LIV3.includes('ERRO'),
          'text-success': !row.item.LIV3.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.LIV3)+" ")])]}},{key:"cell(COL2)",fn:function(row){return [_c('div',{class:{
          'text-danger': row.item.COL2.includes('ERRO'),
          'text-success': !row.item.COL2.includes('ERRO'),
        }},[_vm._v(" "+_vm._s(row.item.COL2)+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-load my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Carregando...")])],1)]},proxy:true}])}),_c('div',{staticClass:"d-flex justify-content-between mb-2"},[_c('div',{staticClass:"d-flex"},[_c('b-pagination',{staticClass:"pagination-poli",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"my-table","disabled":this.$root.requesting},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.loadingPartners && !_vm.busy)?_c('div',{staticClass:"text-center my-1 ml-2 load-new-data sm"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"small":""}}),_c('strong',[_vm._v("Carregando dados...")])],1):_vm._e()],1),_c('div',[_c('b-dropdown',{staticClass:"dropgroup-poli",attrs:{"right":"","dropup":"","text":"Exportar","disabled":this.$root.requesting}},[_c('b-dropdown-item',{on:{"click":_vm.exportAllPartners}},[_vm._v("Exportar todos os parceiros")]),_c('b-dropdown-item',{on:{"click":_vm.exportFilteredPartners}},[_vm._v("Exportar parceiros filtrados")]),_c('b-dropdown-item',{on:{"click":_vm.exportAllLCs}},[_vm._v("Exportar todos os lançamentos contábeis")]),_c('b-dropdown-item',{on:{"click":_vm.exportAdiantmentLCs}},[_vm._v("Exportar lançamentos contábeis de Adiantamento")]),_c('b-dropdown-item',{on:{"click":_vm.exportPartnerLCs}},[_vm._v("Exportar lançamentos contábeis de Contas a Receber")])],1)],1)]),_c('b-modal',{attrs:{"hide-footer":"","hide-header":"","no-close-on-backdrop":"","centered":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center text-load"},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_c('strong',{staticClass:"ml-2"},[_vm._v("Gerando arquivo...")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }