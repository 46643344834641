<template>
  <Highcharts :options="chartOptions" />
</template>

<script>
import router from '../router/index.js'

export default {
  props: {
    dataException: {
      type: Object,
      required: true,  
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Notas de Reconciliação'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            point: {
              events: {
                click: (e) => {           
                  if(e.point.name && e.point.y){
                    if(e.point.name==='Não Reconciliados'){
                      this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                        return !item.reconciliation_note;
                      });
                    } else{
                      if(e.point.name==='Totalmente Reconciliados'){
                        this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                          if(item.reconciliation_note){
                            return item.reconciliation_note.indexOf('ERRO') === -1 && item.reconciliation_note.indexOf('Reconciliado') > -1;
                          }                     
                        });
                      } else{
                        if(e.point.name==='Parcialmente Reconciliados'){
                          this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                            if(item.reconciliation_note){
                              return item.reconciliation_note.indexOf('ERRO') > -1 && item.reconciliation_note.indexOf('Reconciliado') > -1 && !this.dataException.exceptions.some(ex=>item.reconciliation_note.includes(ex.message) );
                            }
                          });
                        }else{
                          this.dataException.exceptions.forEach(exception => {
                            if(e.point.name===exception.name){
                              this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                                if(item.reconciliation_note){
                                  return item.reconciliation_note.indexOf(e.point.message) > -1 && item.reconciliation_note.indexOf('Reconciliado') === -1;
                                }                     
                              });
                            }
                          })
                        }
                      }
                    }

                    router.push({
                      name: "BusinessPartners",
                    });
                  }
                }
              }
            },
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        series: [
          {
            name: 'Total',
            data: [
              {
                name: 'Reconciliados',
                y: this.dataException.partiallyReconciled + this.dataException.totallyReconciled,
                drilldown: 'reconciled'
              },
              {
                name: 'Não Reconciliados',
                y: this.dataException.null,
              },
              {
                name: 'Exceções',
                y: this.dataException.exceptionsCount,
                drilldown: 'exceptions'
              }
            ]
          }
        ],
        drilldown:{
          series:[
            {
              name: "Exceções",
              id: 'exceptions',
              data: this.dataException.exceptions
            },
            {
              name: "Reconciliados",
              id: 'reconciled',
              data: [
                {
                  name: 'Totalmente Reconciliados',
                  y: this.dataException.totallyReconciled,
                },
                {
                  name: 'Parcialmente Reconciliados',
                  y: this.dataException.partiallyReconciled,
                }
              ]
            },
          ]
        }
      }
    };
  },
};
</script>

