<template>
  <Highcharts :options="chartOptions" />
</template>

<script>
import router from '../router/index.js'

export default {
  props: {
    dataRetified: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Retificados'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            point: {
              events: {
                click: (e) => {
                  if(e.point.name && e.point.y){
                    if(e.point.name==='Retificados manualmente'){
                      this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                        return item.rectified && (item.adiantmentBills !== 0 || item.partnerBills !== 0);
                      });
                    } else{
                      if(e.point.name==='Retificados pelo robô'){
                        this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                          return item.rectified && item.adiantmentBills === 0 && item.partnerBills === 0;
                      });
                      } else{
                          if(e.point.name==='Não Retificados'){
                            this.$root.businessPartnerItems = this.$root.businessPartnerItems.filter(item => {
                              return !item.rectified;
                          });
                        }
                      }
                    }

                    router.push({
                      name: "BusinessPartners",
                    });
                  }
                }
              }
            },
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        series: [
          {
            name: 'Total',
            data: [
              {
                name: 'Retificados',
                color: '#1eb5c4',
                y: this.dataRetified.autoRetified + this.dataRetified.manuallyRetified,
                drilldown: 'retifieds'
              }, 
              {
                name: 'Não Retificados',
                color: '#FF9B21',
                y: this.dataRetified.unrectified,
                drilldown: null
              }
            ]
          }
        ],
        drilldown: {
          series: [
            {
              name: "Total",
              id:"retifieds",
              data: [
                {
                  name: 'Retificados manualmente',
                  y: this.dataRetified.manuallyRetified,
                },
                {
                  name: 'Retificados pelo robô',
                  y: this.dataRetified.autoRetified,
                }
              ]
            }
          ]
        }
      }
    };
  },
};
</script>

