<template>
  <b-container
    class="border-poli rounded-lg shadow p-3 mb-5 mt-2 card-filter justify-content-center align-items-center"
  >
    <b-row class="justify-content-center mb-2" :style="{ color: '#787878' }">
      <b-col>
        <b-icon icon="building" scale="2" />
      </b-col>
    </b-row>
    <b-row class="justify-content-center mb-1">
      <b-col class="d-flex justify-content-center">
        <b-dropdown
          text="Selecionar Filiais"
          ref="dropdown"
          class="m-1 dropdown-poli"
          size="sm"
          variant="outline-primary"
        >
          <b-form-checkbox-group
            v-model="selected"
            :options="options"
            class="p-2 switch-poli"
            value-field="item"
            text-field="name"
            :disabled="this.$root.requesting"
            stacked
            switches
            size="sm"
            @change="onChange"
          ></b-form-checkbox-group>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row
      class="justify-content-center text-center"
      :style="{ color: '#787878' }"
    >
      <b-col> Filtrar Filiais </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      selected: this.$root.filters.branches,
      options: [
        { item: "LIV1", name: "LIV1" },
        { item: "EDI1", name: "EDI1" },
        { item: "EDI6", name: "EDI6" },
        { item: "CUR1", name: "CUR1" },
        { item: "NAS1", name: "NAS1" },
        { item: "MMI1", name: "MMI1" },
        { item: "ALO1", name: "ALO1" },
        { item: "COL1", name: "COL1" },
        { item: "SPO1", name: "SPO1" },
        { item: "SPO2", name: "SPO2" },
        { item: "CAM1", name: "CAM1" },
        { item: "CAM2", name: "CAM2" },
        { item: "LIV3", name: "LIV3" },
        { item: "COL2", name: "COL2" },
      ],
    };
  },
  methods: {
    /**
      Change the branches filter variable with the selecteds branches in the variable selected
     */
    onChange() {
      this.$root.filters.branches = this.selected;
    },
  },
};
</script>

