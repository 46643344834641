<template>
  <Highcharts :options="chartOptions" />
</template>

<script>
import router from '../router/index.js'

export default {
  props: {
    dataIdentified: {
      type: Object,
      required: true,  
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Parceiros não identificados'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            point: {
              events: {
                click: function() {
                  if(this.name==='Não Identificados'){
                    router.push({
                      name: "PartnerBills",
                      params: { id: 'IDENTIFICAR' },
                    });
                  }
                }
              }
            },
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        series: [{
          name: 'Valor (R$)',
          data: [{
            name: 'Identificados',
            color: '#1eb5c4',
            y: this.dataIdentified.identified
          }, {
            name: 'Não Identificados',
            color: '#FF9B21',
            y: this.dataIdentified.unidentified
          }]
        }]
      }
    };
  },
};
</script>

