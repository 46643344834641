<template>
  <div>
    <b-container
      class="border-poli unoverflow rounded-lg shadow p-3 mb-5 mt-2 card-filter justify-content-center align-items-center"
    >
      <b-row class="justify-content-center mb-3" :style="{ color: '#787878' }">
        <b-col>
          <b-icon icon="calendar-week" scale="2" />
        </b-col>
      </b-row>

      <b-row class="justify-content-center mb-1 text-center">
        <b-col>
          <span
            id="popover-calendar-de"
            :style="{ fontWeight: 'bold' }"
            class="pointer text-poli"
            >De {{ this.formattedInitialDate }}&nbsp;</span
          ><span
            id="popover-calendar-ate"
            :style="{ fontWeight: 'bold' }"
            class="pointer text-poli"
          >
            até {{ this.formattedEndDate }}</span
          >
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center text-center"
        :style="{ color: '#787878' }"
      >
        <b-col> Período </b-col>
      </b-row>
    </b-container>

    <b-popover
      target="popover-calendar-de"
      triggers="click focus"
      placement="bottom"
      title="Data Inicial"
      :show.sync="initCalendarShow"
    >
      <b-calendar
        width="200px"
        v-model="initialDate"
        @selected="filterInitialDate"
        locale="pt"
        label-help=""
        :disabled="this.$root.requesting"
      ></b-calendar>
      <b-button
        @click="cleanInitialDate"
        size="sm"
        variant="danger"
        class="mr-1 mt-2"
        >Limpar Data</b-button
      >
      <b-button @click="onOk" size="sm" variant="primary" class="mt-2"
        >Ok</b-button
      >
    </b-popover>

    <b-popover
      target="popover-calendar-ate"
      triggers="click"
      placement="bottom"
      title="Data Final"
      :show.sync="endCalendarShow"
    >
      <b-calendar
        width="200px"
        v-model="endDate"
        @selected="filterEndDate"
        locale="pt"
        label-help=""
        :disabled="this.$root.requesting"
      ></b-calendar>
      <b-button
        @click="cleanEndDate"
        size="sm"
        variant="danger"
        class="mr-1 mt-2"
        >Limpar Data</b-button
      >
      <b-button @click="onOk" size="sm" variant="primary" class="mt-2"
        >Ok</b-button
      >
    </b-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initialDate: this.$root.filters.initialDate,
      endDate: this.$root.filters.endDate,
      initCalendarShow: false,
      endCalendarShow: false,
    };
  },
  methods: {
    /**
     * Put a void string in filter initialDate
     */
    cleanInitialDate() {
      this.$root.filters.initialDate = "";
    },

    /**
     * Put a void string in filter endDate
     */
    cleanEndDate() {
      this.$root.filters.endDate = "";
    },

    /**
     * Set the variables show to false, closing the calendars
     */
    onOk() {
      this.initCalendarShow = false;
      this.endCalendarShow = false;
    },
    /**
     * Set the value in variable initialDate to the variable filter initialDate
     */
    filterInitialDate() {
      this.$root.filters.initialDate = this.initialDate;
    },

    /**
     * Set the value in variable endDate to the variable filter endDate
     */
    filterEndDate() {
      this.$root.filters.endDate = this.endDate;
    },
  },
  computed: {
    /**
     * Return the initialDate formatted like yyyy-MM-dd
     * @returns {string}
     */
    formattedInitialDate() {
      if (this.$root.filters.initialDate == "") {
        return "-";
      }
      var parts = this.initialDate.split("-");
      return parts[2] + "/" + parts[1] + "/" + parts[0];
    },

    /**
     * Return the endDate formatted like yyyy-MM-dd
     *
     * @returns {string}
     */
    formattedEndDate() {
      if (this.$root.filters.endDate == "") {
        return "";
      }
      var parts = this.endDate.split("-");
      return parts[2] + "/" + parts[1] + "/" + parts[0];
    },
  },
};
</script>

<style scoped>

.unoverflow{
  min-width: 155px;
}
</style>
