<template>
  <div @click="show = true" class="pointer ml-3 mb-2  ">
    <div v-if="formatedNote.includes('falha')"  >
      <span class="text-danger myHover">
        {{ this.formatedNote }}
      </span>

      <div class="text-secondary">(clique para ver os detalhes)</div>
    </div>

    <div v-else-if="formatedNote != ''" >
      <span class="text-success myHover">
        {{ this.formatedNote }}
      </span>
      <div class="text-secondary ">(clique para ver os detalhes)</div>
    </div>

    <b-modal v-model="show" hide-footer centered title="Informações geradas pelo robô">
      <div
        class="d-flex justify-content-center align-items-center text-load"
        v-for="(item, index) in robotNote"
        :key="index"
      >
        <strong :class="item.includes('ERRO') ? 'text-danger' : 'text-success'">
          {{ index }}: {{ item }}
        </strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    robotNote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    formatedNote() {
      if (!this.robotNote) {
        return "";
      }
      for (let item in this.robotNote) {
        if (this.robotNote[item].includes("ERRO"))
          return "O robô encontrou falhas ao reconciliar";
      }

      return "Reconciliado pelo robô";
    },
  },
  method: {},
};
</script>

<style scoped>

.myHover:hover{
  color: #2B86B3 !important;
}
</style>