<template>
  <b-card style="width: 85%" class="m-3 border-poli shadow">
    <h2 class="mb-3">Criar novo usuário:</h2>
    <b-form @submit.prevent="createUser" class="mb-3">
      <b-form-group
        label="E-mail:"
        label-for="email"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="9"
      >
        <b-form-input
          id="email"
          type="email"
          v-model="email"
          required
          :disabled="this.$root.requesting"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Senha:"
        label-for="password"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="9"
      >
        <b-form-input
          id="password"
          type="password"
          v-model="password"
          :state="state"
          required
          :disabled="this.$root.requesting"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Repita a senha:"
        label-for="repassword"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="9"
        invalid-feedback="As senhas não coincidem"
      >
        <b-form-input
          id="repassword"
          type="password"
          v-model="repassword"
          :state="state"
          required
          :disabled="this.$root.requesting"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Usuário administrador?"
        label-for="repassword"
        label-align-sm="left"
        label-cols-sm="3"
        label-cols-lg="4"
        content-cols-lg="9"
      >
        <b-form-checkbox
          id="checkbox-1"
          v-model="admin"
          value="1"
          unchecked-value="0"
        >
        </b-form-checkbox>
      </b-form-group>

      <b-button  type="submit" :disabled="disabled || this.$root.requesting" class="btn-poli"
        >Criar Usuário</b-button
      >
     
    </b-form>
     <small
        class="mt-3 ml-1"
        :class="{
          'text-danger': answer.includes('Não'),
          'text-success': answer.includes('Sucesso'),
        }"
        v-if="answer != ''"
        >{{ answer }}</small
      >
  </b-card>
</template>

<script>

export default {
  data() {
    return {
      email: "",
      password: "",
      repassword: "",
      admin: 0,
      answer: "",
    };
  },
  computed: {
    /**
     * Return a boolean if the renewPassword equals newPassword
     * @returns {boolean}
     */
    state() {
      if (this.repassword != "") {
        return this.repassword == this.password;
      }
      return null;
    },
    /**
     * Return a boolean if the form is completed
     * @returns {boolean}
     */
    disabled() {
      if (this.state != null) return !this.state || this.email === "";
      return true;
    },
  },
  methods: {

    /**
     * Makes a request to create the user with the variables in form
     */
    async createUser() {
      
        this.$root.requesting = true;
      const response = await this.$root.instance.post("/User",
        {
          email: this.email,
          password: this.password,
          administrator: this.admin,
        },{
          headers: {
            Authorization: "Bearer " + sessionStorage.token,
          },
        }
      );
      if (response.status == 200) {
        this.answer = "Usuário criado com Sucesso!";
        this.$emit("userCreated");
      } else {
        this.answer = "Não foi possível criar o usuário.";
      }
      
        this.$root.requesting = false;
    },
  },
};
</script>
